import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: 'routes',
  component: () => import('shared/ui/TheEmptyRouterView.vue'),
  children: [
    {
      path: '',
      name: PageName.ROUTES_PAGE,
      component: () => import('./pages/RouteMain.vue'),
    },
    {
      path: ':routeId',
      name: PageName.ROUTES_VIEW,
      component: () => import('./pages/RouteView.vue'),
    },
  ],
};

export default (router: Router) => {
  router.addRoute(PageName.BASE_LAYOUT, moduleRoute);
};
