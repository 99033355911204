import { toArray } from 'lodash-es';
import { useSharedUserStore } from 'domains/user/model/user.store';
import type { PermissionRole } from '../enums/common.enum';
import { Role } from '../enums/common.enum';

function hasPermission(
  roles: PermissionRole[],
  roleId?: PermissionRole,
) {
  if (roles == null)
    return true;

  const userStore = useSharedUserStore();

  if (userStore.user) {
    const rolesArray = toArray(roles);
    const currentRoleId = roleId ?? userStore.user.role_id;

    return rolesArray.includes(Role[currentRoleId].value);
  }

  return false;
}

export const canUse = hasPermission;
