export enum PageName {
  BASE_LAYOUT = 'Default',
  AUTH_LAYOUT = 'Auth',
  LOGOUT_PAGE = 'Logout',
  LOGIN_PAGE = 'Login',
  BASE_HOME = 'Home',
  DASHBOARD_PAGE = 'Dashboard',
  TASKS_PAGE = 'Task',
  ROUTES_PAGE = 'Route',
  ROUTES_VIEW = 'RouteView',
  USERS_PAGE = 'User',

  ERROR_500 = 'Error500',
  ERROR_403 = 'Error403',
  ERROR_404 = 'Error404',
}
