type Err = string;

const errorDictionary: { [key in Err]: string } = {
  err_no_rows: 'common.errors.errNoRows',
  service_not_available: 'common.errors.serviceNotAvailable',
  not_authorized: 'common.errors.notAuthorized',
  object_not_found: 'common.errors.objectNotFound',
  incorrect_page_size: 'common.errors.incorrectPageSize',
  bad_status_code: 'common.errors.badStatusCode',
  invalid_id: 'common.errors.invalidId',
  send_limit_exceeded: 'common.errors.sendLimitExceeded',
  security_code_not_valid: 'common.errors.securityCodeNotValid',
  security_code_has_not_sent: 'common.errors.securityCodeHasNotSent',
  frequent_requests: 'common.errors.frequentRequests',
  order_blocked: 'common.errors.orderBlocked',
  already_verified: 'common.errors.alreadyVerified',
  verification_denied: 'common.errors.verificationDenied',
  route_id_required: 'common.errors.routeIdRequired',
  order_id_required: 'common.errors.orderIdRequired',
  invalid_phone: 'common.errors.invalidPhone',
  empty_data: 'common.errors.emptyData',
  checkout_not_allowed: 'common.errors.checkoutNotAllowed',
  phone_already_exist: 'common.errors.phoneAlreadyExist',
  // user errors
  user_name_required: 'common.errors.userNameRequired',
  user_id_required: 'common.errors.userIdRequired',
  user_phone_required: 'common.errors.userPhoneRequired',
  bad_user_phone: 'common.errors.badUserPhone',
  user_deactivated: 'common.errors.userDeactivated',
  // role errors
  role_name_required: 'common.errors.roleNameRequired',
  role_id_required: 'common.errors.roleIdRequired',
};

// Function to get the Russian error message
function getErrorMessage(errorCode: Err): string {
  return errorDictionary[errorCode] || 'common.errors.unknown';
}

export { errorDictionary, getErrorMessage };
