import { storeToRefs } from 'pinia';
import { Executor } from 'shared/api/executor';
import { useLoading } from 'shared/lib/composables/useLoading';
import { SharedUserRepository } from '../api/user.repository';
import type { IUserDTO } from './user.dto';
import { useSharedUserStore } from './user.store';

export function useSharedUserService() {
  const loadingState = useLoading();
  const { user } = storeToRefs(useSharedUserStore());

  async function readProfile() {
    return Executor.run<IUserDTO>({
      request: SharedUserRepository.readProfile(),
      onResult(data) {
        user.value = data;
      },
      onError() {
        throw new Error('Server error');
      },
    });
  }

  return {
    isLoading: loadingState.isLoading,
    readProfile,
  };
}
