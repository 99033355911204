import { useSharedUserService } from 'domains/user/model/user.service';
import { useSharedUserStoreOutside } from 'domains/user/model/user.store';
import type { PermissionRole } from 'shared/lib/enums/common.enum';
import { PageName } from 'shared/lib/types/app/pages';
import { Logger } from 'shared/lib/utils/logger';
import { canUse } from 'shared/lib/utils/permission';
import { useAuthStoreOutside } from 'shared/stores/auth.store';
import type { Router } from 'vue-router';

const _logger = new Logger('Router');

async function getCurrentUser() {
  const userStore = useSharedUserStoreOutside();
  const userService = useSharedUserService();

  if (userStore.user)
    return Promise.resolve();

  return await userService.readProfile();
}

function createPermissionGuard(router: Router) {
  const authStore = useAuthStoreOutside();

  router.beforeEach(async (to) => {
    _logger.log(`%cGoing to %c${to.name as string}`, 'font-weight: bold', 'font-weight: bold; color: blue', to);

    if ([PageName.ERROR_500, PageName.LOGIN_PAGE].includes(to.name as PageName))
      return;

    if (authStore.isLoggedIn) {
      _logger.log('User is logged in');

      try {
        await getCurrentUser();
      } catch (err) {
        return { name: PageName.ERROR_500, replace: true };
      }

      if (!canUse(to.meta.permissions as PermissionRole[])) {
        _logger.log('Access denied. No permission for this route');

        return { name: PageName.ERROR_403, replace: true };
      }

      _logger.log(`Access granted. This route ${to.meta.public ? 'is public' : `available for: ${to.meta.permissions || 'all roles'}`}`);

      return;
    }

    _logger.log('User is logged out');

    if (!to.meta.public && !to.meta.onlyLoggedOut) {
      _logger.log('Access denied. Need authorization');

      return {
        name: PageName.LOGIN_PAGE,
        query: { redirect: to.query.redirect || to.fullPath },
        replace: true,
      };
    }
    _logger.log('Access granted');
  });
}

// DON'T CHANGE THE ORDER OF CREATION
export function setupRouterGuards(router: Router) {
  createPermissionGuard(router);
}
