import { setLocale } from 'yup';
import { i18n } from '../../../i18n/index';

const t = i18n.t;

const ru = {
  mixed: {
    default: (params: any) => t('validation.mixed.default', params),
    required: (params: any) => t('validation.mixed.required', params),
    oneOf: (params: any) => t('validation.mixed.oneOf', { values: params.values }),
    notOneOf: (params: any) => t('validation.mixed.notOneOf', { values: params.values }),
  },
  string: {
    length: (params: any) => t('validation.string.length', { length: params.length }),
    min: (params: any) => t('validation.string.min', { min: params.min }),
    max: (params: any) => t('validation.string.max', { max: params.max }),
    matches: (params: any) => t('validation.string.matches', { regex: params.regex }),
    email: () => t('validation.string.email'),
    url: () => t('validation.string.url'),
    trim: () => t('validation.string.trim'),
    lowercase: () => t('validation.string.lowercase'),
    uppercase: () => t('validation.string.uppercase'),
  },
  number: {
    min: (params: any) => t('validation.number.min', { min: params.min }),
    max: (params: any) => t('validation.number.max', { max: params.max }),
    lessThan: (params: any) => t('validation.number.lessThan', { less: params.less }),
    moreThan: (params: any) => t('validation.number.moreThan', { more: params.more }),
    positive: () => t('validation.number.positive'),
    negative: () => t('validation.number.negative'),
    integer: () => t('validation.number.integer'),
  },
  date: {
    min: (params: any) => t('validation.date.min', { min: params.min }),
    max: (params: any) => t('validation.date.max', { max: params.max }),
  },
  object: {
    noUnknown: () => t('validation.object.noUnknown'),
  },
  array: {
    min: (params: any) => t('validation.array.min', { min: params.min }),
    max: (params: any) => t('validation.array.max', { max: params.max }),
  },
};

export function setLocaleYUP() {
  return setLocale(ru);
}
