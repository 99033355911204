import { version } from '../../../package.json';

const APP_PROD_BASE_URL = getEnvVar('VITE_APP_PROD_BASE_URL');

export const isDevEnv = getEnvVar('DEV');
export const isProdEnv = getEnvVar('PROD');

export const APP_VERSION = version;

export const BASE_URL = isProdEnv ? APP_PROD_BASE_URL : APP_PROD_BASE_URL;
export const IMAGE_SERVICE_URL = getEnvVar('VITE_APP_IMAGE_SERVICE_URL');

export const SENTRY_AUTH_TOKEN = getEnvVar('VITE_SENTRY_AUTH_TOKEN');
export const SENTRY_DSN = getEnvVar('VITE_SENTRY_DSN');

function getEnvVar(key: string) {
  const envVar = window[key as any] as unknown as string || import.meta.env[key];

  if (envVar === undefined)
    throw new Error(`Env variable ${key} is required`);

  return envVar;
}
