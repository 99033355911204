export type PermissionRole = 'admin' | 'manager' | 'director';

interface RoleItem {
  value: PermissionRole
  label: string
}

export const Role: Record<PermissionRole, RoleItem> = {
  admin: { value: 'admin', label: 'common.roles.admin' },
  manager: { value: 'manager', label: 'common.roles.manager' },
  director: { value: 'director', label: 'common.roles.director' },
} as const;
