import 'app/assets/main.css';

import { createApp } from 'vue';

import { setupI18n } from 'i18n/index';
import { vMaska } from 'maska/vue';
import VueApexCharts from 'vue3-apexcharts';
import { setLocaleYUP } from 'shared/lib/utils/validation-utils';
import App from './App.vue';
import { registerModules } from './providers/register-modules';
import { store } from './providers/store';
import { router } from './providers/router/router';
import { setupRouterGuards } from './providers/router/guards';
import { setupSentry } from './plugins/sentry';

export async function initialize() {
  const app = createApp(App);
  app.use(store);
  registerModules(router);
  await setupI18n(app);
  app.directive('maska', vMaska);
  setLocaleYUP();
  app.use(router);
  setupRouterGuards(router);
  setupSentry(app);
  app.use(VueApexCharts);

  app.mount('#app');
}
